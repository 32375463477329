import * as React from "react";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import ImageComponent from "../components/ImageComponent";
import {GatsbySeo} from "gatsby-plugin-next-seo";

const ContactPage = () => {

    return (
        <DefaultLayoutComponent activeCategory="About">
            <GatsbySeo
                title='La Compta de Papa'
                description='Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.'
                canonical='https://lacomptadepapa.fr/nous-contacter'
                openGraph={{
                    url: 'https://lacomptadepapa.fr/nous-contacter',
                    title: 'La Compta de Papa',
                    description: 'Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.',
                }}
            />
            <main className={"pt-5 mt-lg-4 mt-5"}>
                <div className="mt-3 mt-lg-0 slider-area hero-bg-color hero-height2">
                    <div className="slider-active">
                        <div className="single-slider">
                            <div className="slider-cap-wrapper">
                                <div className="hero-caption hero-caption2">
                                    <h2 className={"text-center text-sm-start mb-0"} data-animation="fadeInUp" data-delay=".2s">Nous contacter</h2>
                                </div>
                                <div className="hero-img hero-img2 position-relative" data-animation="fadeInRight" data-transition-duration="5s">
                                    <ImageComponent imageName={"hero3.jpg"} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="contact-title">Envoyez nous un message directement !</h2>
                            </div>
                            <div className="col-lg-8">
                                <form className="form-contact contact_form" action="https://formspree.io/f/xrgjayoe" method="post"
                                      id="contactForm" noValidate="novalidate">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input className="form-control" name="subject" id="subject" type="text"
                                                       placeholder="Sujet"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control w-100" name="message" id="message"
                                                          cols="30" rows="9"
                                                          placeholder="Votre message"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="name" id="name" type="text"
                                                       placeholder="Votre nom"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control valid" name="email" id="email" type="email"
                                                       placeholder="Votre adresse mail"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <input type="hidden" name={'_language'} value={'fr'}/>
                                        <button type="submit" className="button button-contactForm boxed-btn">Envoyer</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-3 offset-lg-1">
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-home"/></span>
                                    <div className="media-body">
                                        <h3>20 rue Jean Moulin</h3>
                                        <p>69490 Vindry-sur-Turdine</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-tablet"/></span>
                                    <div className="media-body">
                                        <h3><a href="tel:+33782169039">+337 82 16 90 39</a></h3>
                                        <p>Du Lundi au vendredi de 9h à 18h !</p>
                                    </div>
                                </div>
                                <div className="media contact-info">
                                    <span className="contact-info__icon"><i className="ti-email"/></span>
                                    <div className="media-body">
                                        <h3>commarmond@lacomptadepapa.fr</h3>
                                        <p>Contactez nous quand vous le souhaitez !</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DefaultLayoutComponent>
    )
};

export default ContactPage;
